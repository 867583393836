import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
export default function Footer() {
  const { t } = useTranslation("home");
  const navigate = useNavigate();
  return (
    <footer className="bg-[#000000] text-white ">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between text-center">
          <div className="mb-6 md:mb-0">
            <p
              onClick={() => navigate("/")}
              className="flex items-center justify-center md:justify-start"
            >
              <img
                src={logo}
                className="w-100 h-15 mb-2 md:h-8 md:me-3"
                alt=" Logo"
              />
            </p>
            <p>
              {t(
                "We are bankers, farmers, and scientists united to enhance the world’s climate resilience."
              )}
               
            </p>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-white">
                {t("AQuick Links")}
              </h2>
              <ul className="text-white font-medium">
                <li onClick={() => navigate("/contact-us")} className="my-4">
                  <p className="hover:underline ">{t("Contact Us")}</p>
                </li>
                <li onClick={() => navigate("/about-us")}>
                  <p className="hover:underline">{t("About Us")}</p>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-white uppercase">
                {t("Support")}
              </h2>
              <ul className="text-white font-medium">
                <li
                  onClick={() =>
                    window.open(
                      "https://www.iubenda.com/terms-and-conditions/12212628",
                      "_blank",
                      "noreferrer"
                    )
                  }
                  className="mb-4"
                >
                  <p className="hover:underline ">{t("Terms & Condition")}</p>
                </li>
                <li
                  onClick={() =>
                    window.open(
                      "https://www.iubenda.com/privacy-policy/12212628",
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  <a
                    href="https://discord.gg/4eeurUVvTy"
                    className="hover:underline"
                  >
                    {t("Privacy Policy")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 sm:mx-auto text-white lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between justify-center">
          <span className="text-sm block  text-center sm:text-center text-white">
            © 2023{" "}
            <a href="" className="hover:underline">
              Adapta™
            </a>
            . {t("All Rights Reserved.")}
          </span>
        </div>
      </div>
    </footer>
  );
}
